"use strict";
/* eslint-disable react/prop-types */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var handleClick = function (href, onClick, preventDefault, navigate, e) {
    if (preventDefault && href === '#') {
        e.preventDefault();
        e.stopPropagation();
    }
    if (onClick) {
        onClick(e);
    }
    // If a navigate hander has been specified, replace the default behaviour
    if (navigate && !e.defaultPrevented) {
        e.preventDefault();
        navigate(href);
    }
};
/**
 * A reusable button. Uses a <a> tag underneath.
 */
var Button = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, children = _a.children, accessibleLabel = _a.accessibleLabel, _c = _a.href, href = _c === void 0 ? '#' : _c, target = _a.target, _d = _a.preventDefault, preventDefault = _d === void 0 ? true : _d, onClick = _a.onClick, dialogTrigger = _a.dialogTrigger, navigate = _a.navigate;
    var hasText = React.Children.count(children) > 0;
    var accessibleElt = accessibleLabel ? (React.createElement("span", { className: "visuallyhidden" }, accessibleLabel)) : null;
    return (React.createElement("a", { className: className, onClick: handleClick.bind(null, href, onClick, preventDefault, navigate), rel: target === '_blank' ? 'noopener noreferrer' : undefined, href: href, target: target, "aria-haspopup": dialogTrigger ? 'dialog' : undefined }, hasText ? children : accessibleElt));
};
exports["default"] = Button;
